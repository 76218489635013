import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "dateInput", "vehicleInput", "output" ]
  static values = {
    url: String
  }

  connect() {
    this.fetchAvailability();
  }

  fetchAvailability(event) {
    let url = new URL(this.urlValue)
    if(this.vehicleInputTarget.value && this.dateInputTarget.value) {
      url.searchParams.set("date", this.dateInputTarget.value);
      url.searchParams.set("vehicle_id", this.vehicleInputTarget.value);

      fetch(url).then(resp => resp.text()).then(html => {
        this.outputTarget.innerHTML = html;
      })
    }
  }
}
